import React from 'react';
import PropTypes from "prop-types";
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import Image from '../utilities/image'

const Values = ( { title, subtitle, values } ) => (
  <StaticQuery
  query={graphql`
    query {
      desktop: file(relativePath: { eq: "values-bg.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `}
  render={data => {
    // Set ImageData.
    const imageData = data.desktop.childImageSharp.fluid
    return (
      <BackgroundImage
        Tag="section"
        className="py-16 md:py-32 mb-16 md:mb-24"
        fluid={imageData}
      >
        <section className="container mx-auto px-2 md:px-4 text-white">
          {subtitle && <span className="block uppercase text-sm font-bold text-white">{subtitle}</span>}
          {title && <h2 className="font-extrabold text-5xl leading-tight mb-10 text-white">{title}</h2>}
          {values && 
            <div className="flex flex-wrap">
              {values.map(value => (
                <div className="w-full sm:w-1/2 md:w-1/4 px-2 mb-4" key={value.title}>
                  <div className="bg-white text-black text-center p-5 h-full flex flex-col justify-center">
                    {value.image && <Image classes="w-16 mx-auto mb-4" filename={value.image.url} alt={value.image.url} />}
                    <h3 className="text-xl font-bold mb-4">{value.title}</h3>
                    {value.text && <p className="text-sm opacity-50 font-semibold">{value.text}</p>}
                  </div>
                </div>
              ))}
            </div>
          }
        </section>
      </BackgroundImage>
    )
  }}
/>
);

Values.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  values: PropTypes.array,
};

export default Values;