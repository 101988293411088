import React from 'react';
import PropTypes from "prop-types";

import Image from './image';

const TextWithImage = ( {container = 'container mx-auto px-2 md:px-4', textFirst, filename, alt, text} ) => (
  <section className={`${container} flex flex-wrap mb-16 md:mb-24 items-center justify-between`}>
      {
        textFirst ?
        <div className="w-full md:w-1/2 lg:w-2/5 mb-8 md:md-0 pr-0 md:pr-10 lg:pr-0 md:mb-0">
          {text}
        </div>
        :
        <div className="w-full md:w-1/2 mb-8 md:md-0">
          <Image classes="w-full" filename={filename} alt={alt} />
        </div>
      }
      {
        textFirst ?
        <div className="w-full md:w-1/2">
          <Image classes="w-full" filename={filename} alt={alt} />
        </div>
        :
        <div className="w-full md:w-1/2 lg:w-2/5 pl-0 md:pl-10 lg:pl-0 md:mb-0">
          {text}
        </div>
      }
  </section>
);

TextWithImage.propTypes = {
  container: PropTypes.string,
  data: PropTypes.object,
  textFirst: PropTypes.bool,
  filename: PropTypes.string,
  alt: PropTypes.string,
  text: PropTypes.element
};

export default TextWithImage;