import React from 'react';
import PropTypes from "prop-types";
import { graphql, StaticQuery } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';


const HomeHero = ( { title, text } ) => (
  <StaticQuery
  query={graphql`
    query {
      desktop: file(relativePath: { eq: "home-background.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `}
  render={data => {
    // Set ImageData.
    const imageData = data.desktop.childImageSharp.fluid
    return (
      <BackgroundImage
        Tag="section"
        className="hero flex flex-wrap items-end relative"
        fluid={imageData}
      >
        <section className="relative z-10 container mx-auto px-2 md:px-4 mb-16 md:mb-64 text-white relative">
          <h2 className="mb-6 text-4xl md:text-6xl font-extrabold leading-tight">
            {title}
          </h2>
          <p className="leading-loose max-w-3xl">
            {text}
          </p>
        </section>
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"/>
      </BackgroundImage>
    )
  }}
/>
);

HomeHero.propTypes = {
  title: PropTypes.element,
  text: PropTypes.string,
};

export default HomeHero;