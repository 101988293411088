import React from 'react';
import PropTypes from "prop-types";
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import TextSection from '../utilities/TextSection';


const TextWithBgImageToFit = ({ container = "container mx-auto px-2 md:px-4" }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "worker.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        demo: file(relativePath: { eq: "demolition.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <div>
          <section className="bg-gray-200 relative">
            <div className="w-full md:mt-0 md:w-1/2 h-64 md:h-full md:absolute top-0 right-0">
              <BackgroundImage 
                Tag="div"
                className="h-full w-full"
                fluid={data.demo.childImageSharp.fluid}
              />
            </div>
            <div className={`${container} flex flex-wrap`}>
              <div className="w-full pb-10 md:w-1/2 pt-16 md:pt-32 md:pb-24 md:pr-16">
                <TextSection 
                  subtitle="Our services"
                  title="Maibo Demolition Specialists"
                  intro="Here at Maibo we are your demolition specialists"
                  text="We have exposure with multilevel projects controlling every step of the demolition process. Our demolition process flow ensures that site safety and site organisation guarantees a smooth running project."
                />
              </div>
            </div>
          </section> 
          <section className="mb-16 md:mb-24 bg-gray-200 relative">
            <div className="w-full md:mt-0 md:w-1/2 h-64 md:h-full md:absolute top-0 right-left">
              <BackgroundImage 
                Tag="div"
                className="h-full w-full"
                fluid={imageData}
              />
            </div>
            <div className={`${container} flex flex-wrap`}>
              <div className="w-full md:w-1/2 pb-10 pt-16 md:pt-32 md:pb-24 md:pl-16 ml-auto">
                <TextSection 
                  subtitle="Our services"
                  title="Maibo Groundworks Specialists"
                  intro="The basis for any successful construction project is a crucial process known as groundworks."
                  text="This is where sub-surfaces for construction are prepared before the actual work begins. At Maibo Groundworks, we understand that our work is a key component in creating strong and stable buildings that will stand on solid foundations."
                />
              </div>
            </div>
          </section>  
        </div> 
      )
    }}
  />
);


TextWithBgImageToFit.propTypes = {
  container: PropTypes.string,
};

export default TextWithBgImageToFit;