import React from 'react';
import PropTypes from "prop-types";

import Image from './image';
import Button from './Button';

const PortfolioSection = ({ container = "container mx-auto px-2 md:px-4", text, images = [] }) => (
  <div>
    <div className={`${container} md:flex flex-wrap justify-between items-center mb-5 md:mb-0`}>
      <div className="flex-1">
        {text}
      </div>
      <Button 
        text="View"
        url="/portfolio"
      />
    </div>
    <div className="flex flex-wrap">
      {images.map(image => (
        <div className="w-1/2 md:w-1/4" key={image.url}>
          <Image classes="w-full portfolio-cta-image" alt={image.alt} filename={image.url} />
        </div>
      ))}
    </div>
  </div>
);

PortfolioSection.propTypes = {
  container: PropTypes.string,
  text: PropTypes.element,
  images: PropTypes.array
};

export default PortfolioSection;