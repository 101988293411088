import React from 'react';
import PropTypes from "prop-types";

import Image from '../utilities/image';

const ReviewBanner = ( { container = 'container mx-auto md:px-4'} ) => (
  <div className={`${container} mb-16 md:mb-24 mt-0 md:-mt-24 relative z-10`} >
    <div className="flex flex-wrap items-center justify-center bg-white shadow-2xl text-2xl text-center py-12">
      Quality assure with
      <Image classes="w-56" alt="checkatrade" filename="checkatrade.jpg" />
      <span className="underline">Rated 9.98</span>
    </div>
  </div>
);

ReviewBanner.propTypes = {
  container: PropTypes.string,
  data: PropTypes.object,
};

export default ReviewBanner;

