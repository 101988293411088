import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HomeHero from "../components/hompepage/Hero";
import ReviewBanner from "../components/hompepage/ReviewBanner";
import TextWithImage from '../components/utilities/TextWithImage';
import TextSection from '../components/utilities/TextSection';
import Values from '../components/hompepage/Values';
import TextWithBgImageToFit from '../components/hompepage/TextWithBgImageToFit';
import PortfolioSection from '../components/utilities/PortfolioSection';

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Maibo Demolition`, `Maibo Groundworks`]}
        title="Get your free estimate"
        description="Based in Essex, we can provide any ground working or demolition solution to suit your needs"
      />
      <HomeHero 
        title={<div>Maibo <br/> Demolition & Groundworks</div>}
        text="We pride ourselves on our high standards. All jobs are completed within a reasonable timeframe whilst maintaining high quality. We ensure that you, the customer, are getting exactly what you require."
      />
      <ReviewBanner />
      <TextWithImage 
        textFirst 
        filename="home-about.jpg" 
        alt="About"
        text={
          <TextSection 
            subtitle="ABOUT US"
            title="Trust and quality is at our core"
            intro="Maibo Demolition & Groundworks established in 2014. Over the years we have built a reputable company by ensuring that our customers always receive an outstanding level of service."
            text="We are an experienced family run business who always follow the same company core principles; work to the highest standards, provide outstanding levels of service and commitment whilst adhering to our safety procedures."
            link={{url: '/contact', text: 'contact us'}}
          />
        }
      />
      <Values 
        subtitle="ABOUT US" 
        title="Our Values" 
        values={[
          {
            image: {
              url: 'testicon.png',
              alt: 'icon'
            },
            title: 'Safety',
            text: 'Health and safety is at the heart of everything we do.'
          },
          {
            image: {
              url: 'testicon.png',
              alt: 'icon'
            },
            title: 'Flexibility',
            text: 'We have flexibility in order to suit our customer’s needs.'
          },
          {
            image: {
              url: 'testicon.png',
              alt: 'icon'
            },
            title: 'Trust',
            text: 'Our reputable company has been built on the foundation of trust.'
          },
          {
            image: {
              url: 'testicon.png',
              alt: 'icon'
            },
            title: 'Quality',
            text: 'We ensure our works are completed to the highest quality.'
          }
        ]}
      />
      <TextWithBgImageToFit />
      <TextWithImage 
        filename="services-image.jpg" 
        alt="Our services"
        text={
          <TextSection 
            subtitle="Our services"
            title="So what can we offer you?"
            intro="We undertake work both as main contractors and sub-contractors. We have carried out major contracts throughout the south east of England, across both public and private sectors."
            text="We can provide any ground working or demolition solution to suit your needs, including single hire of our machinery and drivers."
            link={{url: '/contact', text: 'Free Estimate'}}
          />
        }
      />
      <PortfolioSection 
        text={
          <TextSection 
            subtitle="Portfolio"
            title="Check out our latest work"
          />
        }
        images={[
          {
            url: 'portfolio1.jpg',
            alt: 'Portfolio 1'
          },
          {
            url: 'portfolio2.jpg',
            alt: 'Portfolio 2'
          },
          {
            url: 'pool.jpg',
            alt: 'Portfolio 3'
          },
          {
            url: 'portfolio4.jpg',
            alt: 'Portfolio 4'
          }
        ]}
      />
    </Layout>
  );
}

export default IndexPage;
