import React from 'react';
import PropTypes from "prop-types";

import Button from './Button';

const TextSection = ( { subtitle, title, titleSize = 'text-4xl md:text-5xl', intro, text, link } ) => (
  <div>
    {subtitle && <span className="block uppercase text-sm font-bold text-primary">{subtitle}</span>}
    {title && <h2 className={`${titleSize} font-extrabold leading-tight mb-4`}>{title}</h2>}
    {intro && <h5 className="font-medium text-lg mb-5">{intro}</h5>}
    {text &&<p className="mb-8">{text}</p>}
    {
      link && 
      <Button 
        text={link.text}
        url={link.url}
      />
    }
  </div>
);

TextSection.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  titleSize: PropTypes.string,
  intro: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.object,
};


export default TextSection;