import React from 'react';
import { Link } from 'gatsby'
import PropTypes from "prop-types";

import arrowRight from '../../images/arrow-right.svg';


const Button = ({ text, url }) => (
  <Link className="bg-primary px-8 py-3 text-white text-sm inline-flex flex-wrap items-center duration-300 hover:shadow-lg transform hover:-translate-y-1" to={url}>
    {text}
    <img className="ml-3" src={arrowRight} alt="Arrow"/>
  </Link>
);


Button.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
};

export default Button;